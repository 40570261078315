import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Bio from '../components/Bio'
import Post from '../components/Post'
import Tag from '../components/Tag'
import SEO from '../components/SEO'

import { rhythm } from '../utils/typography'

function Tags({ data, pageContext, location }) {
  const { tag } = pageContext
  const seoTitle = `Tag: ${tag}`
  const tagEdges = data.allMarkdownRemark.edges.filter(({ node }) => {
    if (node.frontmatter.tags) {
      return node.frontmatter.tags.includes(tag)
    }
    return null
  })

  return (
    <Layout location={location} title="thuannp">
      <SEO title={seoTitle} description={seoTitle} />
      <h1
        style={{
          marginTop: rhythm(2.5),
        }}
      >
        Tags: <Tag tag={tag} />
      </h1>
      <main>
        {tagEdges &&
          tagEdges.map(({ node }) => (
            <Post key={node.fields.slug} post={node} />
          ))}
      </main>
      <hr />
      <footer>
        <Bio />
      </footer>
    </Layout>
  )
}

export default Tags

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            tags
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`
